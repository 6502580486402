import React, { useContext, useState } from "react";
import { UserContext } from "../context/UserContext";
import { useNavigate } from "react-router-dom";
import { gapi } from "gapi-script";

const Navbar = ({ onToggleSidebar, isCollapsed }) => {
  const { user } = useContext(UserContext);
  const navigate = useNavigate(); // For navigation
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // State to manage dropdown visibility

  // Logout handler
  const handleLogout = () => {
    // Remove the token from localStorage
    localStorage.removeItem("authToken");
  
    // Revoke Google session
    const authInstance = gapi.auth2.getAuthInstance();
    if (authInstance) {
      authInstance.signOut().then(() => {
        authInstance.disconnect();
        console.log("User signed out.");
      });
    }
  
    // Clear browser history and cache
    if ("caches" in window) {
      caches.keys().then((names) => {
        names.forEach((name) => caches.delete(name));
      });
    }
  
    // Prevent back navigation
    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", () => {
      window.history.pushState(null, "", window.location.href);
    });
  
    // Redirect to the login page
    window.location.href = "/signin"; // Adjust the URL as necessary
  };
  

  // Navigate to the user's profile
  const handleProfileNavigation = () => {
    navigate("username-profile"); // Adjust the route as needed for your app
  };

  return (
    <div
      className="flex justify-between items-center px-4 py-2 h-16 bg-white shadow-md border-b border-gray-200 transition-all duration-300"
      style={{ marginLeft: isCollapsed ? "80px" : "226px" }}
    >
      <div className="flex items-center space-x-4">
        <button
          onClick={onToggleSidebar}
          className="p-2 rounded-md hover:bg-gray-100"
          aria-label="Toggle Sidebar"
        >
          <span className="icon-sidebar-toggle text-xl">&#9776;</span>
        </button>
        <div className="relative">
          <input
            type="text"
            placeholder="Search"
            className="pl-10 pr-4 py-2 w-80 bg-gray-100 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
          />
          <span className="absolute left-3 top-2 text-gray-500">&#128269;</span>
        </div>
      </div>
      <div className="flex items-center space-x-6">
        <div className="relative space-x-5">
          <button
            className="bg-skyblue text-white px-6 mr-15 h-12 py-2 rounded-lg shadow-md hover:bg-blue-700"
            aria-label="Notifications"
          >
            <a
              href="https://incidentdetection.thecontracto.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="icon-notification text-lg">
                AI Collision Detection
              </span>
            </a>
          </button>

          <button
            className="p-2 rounded-md hover:bg-gray-100"
            aria-label="Notifications"
          >
            <span className="icon-notification text-xl">&#128276;</span>
          </button>
          <span className="absolute top-0 right-0 inline-flex items-center justify-center px-1 py-0.5 text-xs font-bold leading-none text-white bg-red-500 rounded-full">
            4
          </span>
        </div>
        <div className="relative mr-30">
          <div
            className="flex items-center space-x-2 cursor-pointer"
            onClick={() => setIsDropdownOpen(!isDropdownOpen)} // Toggle dropdown
          >
            {user && user.image ? (
              <>
                <img
                  src={user.image}
                  alt="User Avatar"
                  className="w-8 h-8 rounded-full"
                />
                <span className="text-gray-700 font-medium">
                  {user.name || "User"}
                </span>
              </>
            ) : (
              <div className="text-gray-500">Guest</div>
            )}
          </div>
          {isDropdownOpen && (
            <div className="absolute right-0 mt-2 w-48 bg-blue-600 rounded-md shadow-lg">
              <button
                onClick={handleProfileNavigation}
                className="block w-full text-left px-4 py-2 text-white font-bold hover:bg-white hover:text-blue-600 rounded-t-md"
              >
                {user.name.toUpperCase()}'S PROFILE
              </button>
              <button
                onClick={handleLogout}
                className="block w-full text-left px-4 py-2 text-white font-bold hover:bg-white hover:text-blue-600 rounded-b-md"
              >
                LOGOUT
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
