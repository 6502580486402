import React from 'react';


const Analytics = () => {
  return (
    <div className="flex">

    </div>
  );
};

export default Analytics;