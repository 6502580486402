import React, { useContext, useState } from 'react';
import { UserContext } from '../context/UserContext';
import { NavLink } from 'react-router-dom';

const Dashboard = () => {
  const { user } = useContext(UserContext); // Access user data from context
  const firstName = user && user.name ? user.name.split(' ')[0] : "Guest";

  // State for pagination and selection
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRows, setSelectedRows] = useState(new Set());
  const totalRecords = 50; // Example total records
  const recordsPerPage = 10;

  const totalPages = Math.ceil(totalRecords / recordsPerPage);
  const startRecord = (currentPage - 1) * recordsPerPage;
  const endRecord = startRecord + recordsPerPage;

  // Handlers for pagination
  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };
  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  // Handlers for row selection
  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedRows(new Set([...Array(recordsPerPage).keys()].map(i => i + startRecord)));
    } else {
      setSelectedRows(new Set());
    }
  };
  const handleSelectRow = (index) => {
    const newSelectedRows = new Set(selectedRows);
    if (newSelectedRows.has(index)) newSelectedRows.delete(index);
    else newSelectedRows.add(index);
    setSelectedRows(newSelectedRows);
  };

  return (
    <div className="p-6">
      {/* Greeting Section */}
      <div className="text-4xl font-semibold text-darkblue mb-8">
        {user ? (
          <p>Hi {firstName}, here are your live stats</p>
        ) : (
          <p>Please sign in to view your report</p>
        )}
      </div>

      {/* Cards Section */}
      <div className="grid grid-cols-3 gap-6 mb-10 w-full">
        {/* Card 1 - Total Events */}
        <NavLink to="/dashboard/events" className="bg-white rounded-lg shadow-lg p-6 text-center h-30">
          <div className="text-2xl font-medium text-gray-700 pb-5">Total Events</div>
          <div className="text-xl text-skyblue text-bold">6</div>
        </NavLink>
        
        {/* Card 2 - Near Miss Incidents */}
        <NavLink to="/dashboard/nearmiss" className="bg-white rounded-lg shadow-lg p-6 text-center h-30">
          <div className="text-2xl font-medium text-gray-700 pb-5">Near Miss Events</div>
          <div className="text-xl text-red-500 text-bold">0</div>
        </NavLink>
        
        {/* Card 3 - Pending Actions */}
        <NavLink to="/dashboard/actions" className="bg-white rounded-lg shadow-lg p-6 text-center h-30">
          <div className="text-2xl font-medium text-gray-700 pb-5">Pending Actions</div>
          <div className="text-xl text-skyblue text-bold">3</div>
        </NavLink>

        {/* Card 4 - Cameras Online */}
        <NavLink to="/dashboard/cameras" className="bg-white rounded-lg shadow-lg p-6 text-center h-30">
          <div className="text-2xl font-medium text-gray-700 pb-5">Cameras Online</div>
          <div className="text-xl text-green-500 text-bold">0</div>
        </NavLink>

        {/* Card 5 - Cameras Offline */}
        <NavLink to="/dashboard/cameras" className="bg-white rounded-lg shadow-lg p-6 text-center h-30">
          <div className="text-2xl font-medium text-gray-700 pb-5">Cameras Offline</div>
          <div className="text-xl text-red-500 text-bold">0</div>
        </NavLink>

        {/* Card 6 - Available Cameras */}
        <NavLink to="/dashboard/cameras" className="bg-white rounded-lg shadow-lg p-6 text-center h-30">
          <div className="text-2xl font-medium text-gray-700 pb-5">Available Cameras</div>
          <div className="text-xl text-skyblue">65</div>
        </NavLink>
      </div>

      <div className="text-4xl font-semibold text-darkblue mb-8">
        <p>Incident Report</p>
      </div>

      {/* Pagination Control - Top */}
      <div className="flex justify-between items-center mb-4">
        <button onClick={handlePrevPage} className="bg-gray-200 w-20 h-10 rounded-md hover:bg-gray-300">Previous</button>
        <span className="text-gray-600">{currentPage} / {totalPages}</span>
        <button onClick={handleNextPage} className="bg-gray-200 w-20 h-10 rounded-md hover:bg-gray-300">Next</button>
      </div>

      {/* Table Section */}
      <div className="overflow-auto bg-white shadow-lg rounded-lg">
        <table className="min-w-full bg-white">
          <thead className="bg-gray-100 border-b">
            <tr>
              <th className="py-4 px-6 text-left">
                <input
                  type="checkbox"
                  onChange={handleSelectAll}
                  checked={selectedRows.size === recordsPerPage}
                  className="bg-gray-200 rounded-md"
                />
              </th>
              <th className="py-4 px-6 text-left text-md font-bold text-gray-600 uppercase">Name</th>
              <th className="py-4 px-6 text-left text-md font-bold text-gray-600 uppercase">Location</th>
              <th className="py-4 px-6 text-left text-md font-bold text-gray-600 uppercase">Incident Description</th>
              <th className="py-4 px-6 text-left text-md font-bold text-gray-600 uppercase">Date - time</th>
              <th className="py-4 px-6 text-left text-md font-bold text-gray-600 uppercase">Actions</th>
            </tr>
          </thead>
          <tbody>
            {[...Array(recordsPerPage).keys()].map((index) => {
              const recordIndex = startRecord + index;
              return (
                <tr key={recordIndex} className="border-b hover:bg-gray-50">
                  <td className="py-4 px-6">
                    <input
                      type="checkbox"
                      checked={selectedRows.has(recordIndex)}
                      onChange={() => handleSelectRow(recordIndex)}
                      className="bg-gray-200 rounded-md"
                    />
                  </td>
                  <td className="py-4 px-6 text-md text-gray-700">Camera {recordIndex + 1}</td>
                  <td className="py-4 px-6 text-md text-gray-700">Location {recordIndex + 1}</td>
                  <td className="py-4 px-6 text-md text-gray-700">Incident {recordIndex + 1}</td>
                  <td className="py-4 px-6 text-md text-gray-700">Date-time {recordIndex + 1}</td>
                  <td className="py-4 px-6 text-sm text-gray-700 flex space-x-2 opacity-0 hover:opacity-100">
                    <button className="text-blue-500">Edit</button>
                    <button className="text-red-500">Delete</button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {/* Pagination Control - Bottom */}
      <div className="flex justify-between items-center mt-5 mb-4">
        <button onClick={handlePrevPage} className="bg-gray-200 w-20 h-10 rounded-md hover:bg-gray-300">Previous</button>
        <span className="text-gray-600">{currentPage} / {totalPages}</span>
        <button onClick={handleNextPage} className="bg-gray-200 w-20 h-10 rounded-md hover:bg-gray-300">Next</button>
      </div>
    </div>
  );
};

export default Dashboard;
