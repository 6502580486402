import React from "react";
import MSImage from "../Assets/forkliftppe.jpg"; // Replace with actual image path
import Msgicon from "../Assets/Messageicon.svg"
const CustomerFeedback = () => {
  return (
    <div className="flex flex-col items-center bg-gradient-to-t from-orange to-skyblue py-20 px-4 lg:px-0">
      {/* Title */}
      <h1 className="text-5xl font-semibold text-darkblue mb-20 text-center">
        Your Trusted OHSE Partner for Compliance and Performance enhancement      </h1>
      {/* Feedback Card */}
      <div className="relative flex flex-col lg:flex-row max-w-7xl w-full space-y-6 lg:space-y-0 lg:space-x-12">
        {/* Positioned Icon */}
        <img
          src={Msgicon}
          alt="Quote Icon"
          className="absolute top-[-30px] left-[80px] lg:top-30 lg:left-[80px] w-15 h-15"
        />
        {/* Card Section */}
        <div className="flex flex-col justify-start align-center p-10 bg-white rounded-2xl shadow-lg  lg:text-left lg:w-1/2 h-[450px] w-[750px]">
          
          <h1 className="text-4xl font-regular mb-10 mt-10 text-center">Mahaveer Food Industries</h1>
          <p className="text-lg text-gray-700 mb-10 text-center">
          Contracto AI has transformed our approach to safety by providing complete transparency into our risk profile. It empowers our team to identify unsafe incidents remotely, recognize patterns in recurring issues, and implement targeted training programs. This proactive approach is driving a positive shift in our safety culture within Mahaveer Food Industries.          </p>
          <div className="flex flex-row justify-center text-xl mb-10">
            <span className="font-medium text-gray-500 px-3">Director</span>
            <span className="font-medium px-3">Sandeep Rao</span>
          </div>
          {/* <div className="flex justify-center w-full">
            <button className="bg-buttonBlue text-white px-8 py-3 mt-4 rounded-lg font-medium shadow-md hover:bg-blue-600 w-[200px] h-[50px]">
              Read Case Study
            </button>
          </div> */}
        </div>

        {/* Image Section */}
        <div className="rounded-2xl overflow-hidden shadow-lg lg:w-1/2 h-[450px] w-[750px]">
          <img
            src={MSImage}
            alt="M&S Case Study"
            className="w-full h-full object-cover"
          />
        </div>
      </div>
    </div>
  );
};

export default CustomerFeedback;
