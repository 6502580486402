import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Bar, Radar, Line } from 'react-chartjs-2';
import ViewEventsPanel from '../Widgets/ViewEventPanel'; // Import the separate panel

const API_BASE_URL = 'https://api-e5tzoauvmq-uc.a.run.app';

const Events = () => {
  const [events, setEvents] = useState([]); // Stores the list of events
  const [selectedEvent, setSelectedEvent] = useState(null); // Stores the selected event for the sidebar
  const [isLoading, setIsLoading] = useState(true); // Indicates loading state
  const [errorMessage, setErrorMessage] = useState(''); // Error message if fetching fails
  const [chartData, setChartData] = useState({
    weekly: null,
    radar: null,
    horizontal: null,
  }); // Chart data for all charts

  const priorityColors = {
    urgent: 'bg-red text-white',
    high: 'bg-orange text-white',
    medium: 'bg-yellow text-white',
    low: 'bg-green text-white',
  };


  

  // Fetch CamerasData from the backend
  useEffect(() => {
    const fetchEvents = async () => {
      try {
        setIsLoading(true);
        
        const response = await axios.get(`${API_BASE_URL}/api/database/fetch-cameras-data`);
        if (response.data.success) {
          const fetchedEvents = response.data.data;
          setEvents(fetchedEvents); // Populate events with fetched data
          setErrorMessage('');
    
          // Prepare chart data
          prepareChartData(fetchedEvents);
    
          // Populate Actions database with events
          await populateActions(fetchedEvents);
        } else {
          setErrorMessage('Failed to fetch events. Please try again.');
          console.error('Error fetching events:', response.data.message);
        }
      } catch (error) {
        setErrorMessage('An error occurred while fetching events.');
        console.error('Error fetching events:', error.message);
      } finally {
        setIsLoading(false);
      }
    };
    
    const populateActions = async () => {
      try {
        const response = await axios.post(`${API_BASE_URL}/api/database/populate-actions-from-cameras`);

        if (response.data.success) {
          console.log('Actions successfully populated:', response.data.data);
        } else {
          console.error('Failed to populate actions:', response.data.message);
        }
      } catch (error) {
        console.error('Error populating actions:', error.message);
      }
    };
    

    fetchEvents();
  }, []);

  // Prepare chart data
  const prepareChartData = (data) => {
    if (!data || data.length === 0) return;

    

    // Weekly Bar Chart Data
    const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    const weeklyData = {
      labels: daysOfWeek,
      datasets: [
        {
          label: 'Human to Machine',
          data: Array(7).fill(0),
          backgroundColor: 'rgba(75, 192, 192, 0.6)',
        },
        {
          label: 'Machine to Object',
          data: Array(7).fill(0),
          backgroundColor: 'rgba(255, 99, 132, 0.6)',
        },
        {
          label: 'Machine to Machine',
          data: Array(7).fill(0),
          backgroundColor: 'rgba(54, 162, 235, 0.6)',
        },
      ],
    };

    // Radar Chart Data
    const radarData = {
      labels: ['Human to Machine', 'Machine to Object', 'Machine to Machine'],
      datasets: [
        {
          label: 'Collisions Count',
          data: [0, 0, 0],
          backgroundColor: 'rgba(153, 102, 255, 0.6)',
        },
      ],
    };

    // Horizontal Bar Chart Data
    const locationMap = {};
    const horizontalData = {
      labels: [],
      datasets: [
        {
          label: 'Collisions by Location',
          data: [],
          backgroundColor: 'rgba(255, 206, 86, 0.6)',
        },
      ],
    };

    // Populate the chart datasets based on the data
    data.forEach((event) => {
      const eventDay = new Date(event.created_at).getDay();
      const collisionType = event.description;
      const location = event.location;

      // Weekly Data
      if (collisionType === 'Human to machine collision') {
        weeklyData.datasets[0].data[eventDay]++;
        radarData.datasets[0].data[0]++;
      } else if (collisionType === 'Machine to object collision') {
        weeklyData.datasets[1].data[eventDay]++;
        radarData.datasets[0].data[1]++;
      } else if (collisionType === 'Machine to machine collision') {
        weeklyData.datasets[2].data[eventDay]++;
        radarData.datasets[0].data[2]++;
      }

      // Horizontal Data
      if (!locationMap[location]) {
        locationMap[location] = 0;
      }
      locationMap[location]++;
    });

    // Update Horizontal Data
    horizontalData.labels = Object.keys(locationMap);
    horizontalData.datasets[0].data = Object.values(locationMap);

    // Set Chart Data
    setChartData({
      weekly: weeklyData,
      radar: radarData,
      horizontal: horizontalData,
    });
  };

  return (
    <div className="h-screen flex flex-row bg-gray-100 mb-20">
      {/* Main Content */}
      <div className="flex-1 p-6 pr-0 w-3/4">
        <h1 className="text-2xl font-bold text-gray-800 mb-6">Camera Events</h1>

        {/* Show loading spinner or error message */}
        {isLoading ? (
          <p className="text-gray-500 text-center">Loading events...</p>
        ) : errorMessage ? (
          <p className="text-red-500 text-center">{errorMessage}</p>
        ) : events.length > 0 ? (
          <div className="flex flex-col gap-6">
            {events.map((event) => (
              <div
                key={event.id}
                className="flex w-full bg-white shadow-md rounded-lg p-4 cursor-pointer hover:shadow-lg transition"
                onClick={() => setSelectedEvent(event)}
              >
                <div className="w-2/5">
                  <img
                    src={event.media_url}
                    alt={event.description}
                    className="w-full h-full object-cover rounded-md"
                  />
                </div>
                <div className="w-3/5 pl-4 py-4 flex flex-col">
                  <div className="space-y-4">
                    <h1 className="text-2xl font-bold text-gray-700">{`${event.description}`}</h1>
                    <h1 className="text-lg font-semibold text-gray-500">{`Camera #${event.camera_id}`}</h1>
                    <h2 className="text-lg text-gray-500">{new Date(event.created_at).toLocaleString()}</h2>
                  </div>
                  <div className="flex flex-col mt-2 space-y-4">
                    <span
                      className={`text-md font-semibold text-center w-[80px] h-15 px-2 py-1 rounded-2xl ${priorityColors[event.priority]}`}
                    >
                      {event.priority}
                    </span>
                    <p className="text-md text-gray-500">{`Location: ${event.location}`}</p>
                    <p className="text-md text-bold text-gray-500">{`Distance: ${event.distance} meters`}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p className="text-gray-500 text-center">No events available.</p>
        )}
      </div>

      {/* Charts Section */}
      <div className="flex-1 pl-6 ml-10 w-1/4">
        <h1 className="text-2xl top-5 font-bold text-gray-800 mb-6">Collision Analytics</h1>
        <div className="grid grid-cols-1 gap-6">
          {/* Weekly Bar Chart */}
          {chartData.weekly && (
            <div className="p-4 bg-gray-50 shadow-lg rounded-lg">
              <Bar data={chartData.weekly} />
            </div>
          )}
          {/* Radar Chart */}
          {chartData.radar && (
            <div className="p-4 bg-gray-50 shadow-lg rounded-lg">
              <Radar data={chartData.radar} />
            </div>
          )}
          {/* Horizontal Bar Chart */}
          {chartData.horizontal && (
            <div className="p-4 bg-gray-50 shadow-lg rounded-lg">
              <Bar data={chartData.horizontal} options={{ indexAxis: 'y' }} />
            </div>
          )}
        </div>
      </div>

      {/* ViewEventsPanel for Selected Event */}
      {selectedEvent && (
        <ViewEventsPanel
          event={selectedEvent}
          onClose={() => setSelectedEvent(null)}
        />
      )}
    </div>
  );
};

export default Events;
