import React from 'react';


// const API_BASE_URL = 'https://api-e5tzoauvmq-uc.a.run.app';
const ViewEventPanel = ({ event, onClose }) => {
  const priorityColors = {
    urgent: 'bg-red text-white',
    high: 'bg-orange text-white',
    medium: 'bg-yellow text-white',
    low: 'bg-green text-white',
  };

  return (
    <div className="fixed right-0 top-[55px] h-[calc(100%-55px)] w-full sm:w-2/3 md:w-1/2 lg:w-2/5 bg-gray-50 shadow-lg rounded-xl p-8 flex flex-col space-y-8 overflow-y-auto">
      <button
        onClick={onClose}
        className="absolute top-12 right-2 rounded-full p-2"
      >
        ✖
      </button>
      <h1 className="text-2xl font-bold text-gray-700">{`${event.description}`}</h1>
      <div className='flex flex-row space-x-5'>
        <h2 className="text-xl font-bold text-gray-800 mb-4">{`Camera #${event.camera_id}`}</h2>
        <h2 className="text-lg text-gray-500">{new Date(event.created_at).toLocaleString()}</h2>
      </div>
      <img
        src={event.media_url}
        alt={event.description}
        className="w-full h-85 object-cover rounded-md"
      />
      <div>
        {/* <p className="text-sm text-gray-500 mt-2">{`Location: ${event.location}`}</p> */}
        {/* <p className="text-sm text-gray-500">{`Distance: ${event.distance} meters`}</p> */}
        <div className="flex flex-col mt-2 space-y-4">
            <span
            className={`text-md font-semibold text-center w-[80px] h-15 px-2 py-1 rounded-2xl ${priorityColors[event.priority]}`}
            >
                {event.priority}
            </span>
            <p className="text-md text-gray-500">{`Location of Incident: ${event.location}`}</p>
            {/* <p className="text-md text-gray-500">{`Description of: ${event.description}`}</p> */}
            <p className="text-md text-bold text-gray-500">{`Distance of Collision: ${event.distance} meters`}</p>
        </div>
        {/* <p className="text-sm text-gray-600 mt-4">{event.description}</p> */}
      </div>
    </div>
  );
};

export default ViewEventPanel;

