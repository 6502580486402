import React from 'react';
import { NavLink } from 'react-router-dom';
import Contractologo from '../Assets/Contracto-logo.png';
import Dashboardicon from '../Assets/dashboard.png';
import Usericon from '../Assets/user.png';
import Analyticsicon from '../Assets/web.png';
import configurationsicon from '../Assets/preferences.png';
import RulesIcon from '../Assets/Ruleicon.png';
import EventIcon from '../Assets/Eventicon.png';
import CameraIcon from '../Assets/Cameraicon.png';
import ActionIcon from '../Assets/Actionicon.png';
import Nearmissicon from '../Assets/warning.png';
import ppeicon from '../Assets/protective-equipement.png';

const Sidebar = ({ isCollapsed }) => {
  return (
    <div
      className={`h-screen bg-seablue text-white fixed top-0 left-0 shadow-lg transition-all duration-300 ${
        isCollapsed ? 'w-20' : 'w-55'
      }`}
      style={{
        overflow: 'hidden',
      }}
    >
      {/* Logo */}
      {!isCollapsed && (
        <div className="bg-white flex justify-center items-center py-4">
          <img src={Contractologo} alt="Contracto Logo" className="h-16" />
        </div>
      )}

      {/* Navigation Links */}
      <div className="flex-grow overflow-y-auto">
        <nav>
          <NavLink
            to="/dashboard"
            className="flex items-center space-x-4 p-5 hover:bg-skyblue text-white"
          >
            <img src={Dashboardicon} alt="Dashboard Icon" className="w-6 h-6" />
            {!isCollapsed && <span>DASHBOARD</span>}
          </NavLink>
          <NavLink
            to="/dashboard/actions"
            className="flex items-center space-x-4 p-5 hover:bg-skyblue text-white"
          >
            <img src={ActionIcon} alt="Actions Icon" className="w-6 h-6" />
            {!isCollapsed && <span>ACTIONS</span>}
          </NavLink>
          {/* <NavLink
            to="/dashboard/nearmiss"
            className="flex items-center space-x-4 p-5 hover:bg-skyblue text-white"
          >
            <img src={Nearmissicon} alt="Near Miss Icon" className="w-6 h-6" />
            {!isCollapsed && <span>COLLISION MISS</span>}
          </NavLink> */}
          {/* <NavLink
            to="/dashboard/analytics"
            className="flex items-center space-x-4 p-5 hover:bg-skyblue text-white"
          >
            <img src={Analyticsicon} alt="Analytics Icon" className="w-6 h-6" />
            {!isCollapsed && <span>ANALYTICS</span>}
          </NavLink> */}
          {/* <NavLink
            to="/dashboard/rules"
            className="flex items-center space-x-4 p-5 hover:bg-skyblue text-white"
          >
            <img src={RulesIcon} alt="Rules Icon" className="w-6 h-6" />
            {!isCollapsed && <span>RULES</span>}
          </NavLink> */}
          <NavLink
            to="/dashboard/ppeuser"
            className="flex items-center space-x-4 p-5 hover:bg-skyblue text-white"
          >
            <img src={ppeicon} alt="PPE Detection Icon" className="w-6 h-6" />
            {!isCollapsed && <span>PPE DETECTION</span>}
          </NavLink>
          <NavLink
            to="/dashboard/events"
            className="flex items-center space-x-4 p-5 hover:bg-skyblue text-white"
          >
            <img src={EventIcon} alt="Event Icon" className="w-6 h-6" />
            {!isCollapsed && <span>COLLISION EVENTS</span>}
          </NavLink>
          <NavLink
            to="/dashboard/cameras"
            className="flex items-center space-x-4 p-5 hover:bg-skyblue text-white"
          >
            <img src={CameraIcon} alt="Cameras Icon" className="w-6 h-6" />
            {!isCollapsed && <span>CAMERAS</span>}
          </NavLink>
          <NavLink
            to="/dashboard/user-profile"
            className="flex items-center space-x-4 p-5 hover:bg-skyblue text-white"
          >
            <img src={Usericon} alt="User Profile Icon" className="w-6 h-6" />
            {!isCollapsed && <span>EMPLOYEE PROFILES</span>}
          </NavLink>
          {/* <NavLink
            to="/dashboard/configuration"
            className="flex items-center space-x-4 p-5 hover:bg-skyblue text-white"
          >
            <img
              src={configurationsicon}
              alt="Configuration Icon"
              className="w-6 h-6"
            />
            {!isCollapsed && <span>CONFIGURATION</span>}
          </NavLink> */}
        </nav>
      </div>
    </div>
  );
};

export default Sidebar;
