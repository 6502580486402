import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { UserProvider } from './context/UserContext';
import HeroSection from "./WebsiteComponents/herosection";
import Header from "./WebsiteComponents/Header";
import RiskTypes from './WebsiteComponents/Risktypes';
import CustomerFeedback from './WebsiteComponents/customerfeedback';
import PrivacySecuirty from './WebsiteComponents/privacy';
import Footer from './WebsiteComponents/footer';
import RiskCategory from './WebsiteComponents/riskcategory';
import ProcessFramework from './WebsiteComponents/processframework';
import SignIn from './WebsiteComponents/SignIn';
import SignUp from './WebsiteComponents/SignUp';

// Import Dashboard Components
import DashboardLayout from './DashboardComponents/DashboardLayout';
import Dashboard from './DashboardComponents/Dashboard';
import UserProfile from './DashboardComponents/UserProfile';
import Analytics from './DashboardComponents/Analytics';
import Users from './DashboardComponents/UserNameProfile';
import Settings from './DashboardComponents/Settings';
import Rules from './DashboardComponents/Rules';
import Actions from './DashboardComponents/Actions';
import Events from './DashboardComponents/Events';
import Cameras from './DashboardComponents/Cameras';
import NearMiss from './DashboardComponents/NearMiss';
import PpeUser from './DashboardComponents/PpeUser';
import UserNameProfile from './DashboardComponents/UserNameProfile';

function App() {
  return (
    <UserProvider>
      <Router basename={process.env.PUBLIC_URL || '/'}>
        <Routes>
          {/* Main Application Route */}
          <Route
            path="/"
            element={
              <>
                <Header />
                <HeroSection />
                <RiskTypes />
                <CustomerFeedback />
                <RiskCategory />
                <ProcessFramework />
                <PrivacySecuirty />
                <Footer />
              </>
            }
          />

          {/* Sign-In Page Route */}
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />

          {/* Dashboard Routes with Nested Layout */}
          <Route path="/dashboard" element={<DashboardLayout />}>
            <Route path="" element={<Dashboard />} />
            <Route path="user-profile" element={<UserProfile />} />
            <Route path="analytics" element={<Analytics />} />
            <Route path="cameras" element={<Cameras />} />
            <Route path="rules" element={<Rules />} />
            <Route path="actions" element={<Actions />} />
            <Route path="events" element={<Events />} />
            <Route path="nearmiss" element={<NearMiss />} />
            <Route path="ppeuser" element={<PpeUser />} />
            

            {/* Configuration Routes */}
            <Route path="configuration/users" element={<Users />} />
            <Route path="configuration/settings" element={<Settings />} />

            {/* User Profile Routes */}
            <Route path="username-profile" element={<UserNameProfile />} />
          </Route>
        </Routes>
      </Router>
    </UserProvider>
  );
}

export default App;