import React, { useState, useRef } from "react";
import VehicleControlIcon from "../Assets/vehiclecontrol.svg";
import PpeDetectionIcon from "../Assets/ppedetection.svg";
import BehavioralSafetyIcon from "../Assets/behaviouralsafety.svg"; 
import ErgonomicsIcon from "../Assets/ergonomics.svg";
import HousekeepingIcon from "../Assets/housekeeping.svg";

const categories = [
  {
    icon: VehicleControlIcon,
    title: "Compliance",
    description:
      "Contracto AI actively enforces compliance for vehicles and operators within designated zones, maintaining safe speeds, upholding exclusion areas, and ensuring adherence to PPE standards.",
  },
  {
    icon: PpeDetectionIcon,
    title: "PPE Detection",
    description:
      "Our system quickly detects if workers are missing critical PPE, including hard hats, gloves, masks, and other essential safety gear.",
  },
  {
    icon: BehavioralSafetyIcon,
    title: "Work Site Safety",
    description:
      "Contracto AI proactively minimizes risk by identifying potential hazards, such as pedestrian presence in operational zones, to prevent injuries.",
  },
  {
    icon: ErgonomicsIcon,
    title: "Ergonomics",
    description:
      "With real-time ergonomic monitoring, Contracto AI addresses posture-related risks early, helping to reduce both immediate and long-term worker injuries.",
  },
  {
    icon: HousekeepingIcon,
    title: "Housekeeping",
    description:
      "Our system supports robust safety through perimeter controls, restricted area monitoring, and exposure limitation strategies, all designed to safeguard worker health.",
  },
];


const RiskCategory = () => {
  const [scrollProgress, setScrollProgress] = useState(0);
  const cardContainerRef = useRef(null);

  // Drag scroll logic
  const isDragging = useRef(false);
  const startPosition = useRef(0);
  const scrollLeft = useRef(0);

  const handleMouseDown = (e) => {
    isDragging.current = true;
    startPosition.current = e.pageX - cardContainerRef.current.offsetLeft;
    scrollLeft.current = cardContainerRef.current.scrollLeft;
  };

  const handleMouseLeave = () => {
    isDragging.current = false;
  };

  const handleMouseUp = () => {
    isDragging.current = false;
  };

  const handleMouseMove = (e) => {
    if (!isDragging.current) return;
    e.preventDefault();
    const x = e.pageX - cardContainerRef.current.offsetLeft;
    const walk = (x - startPosition.current) * 2; // scroll-fast
    cardContainerRef.current.scrollLeft = scrollLeft.current - walk;
  };

  // Calculate the scroll position for the progress bar
  const handleScroll = () => {
    const container = cardContainerRef.current;
    if (container) {
      const scrollLeft = container.scrollLeft;
      const maxScrollLeft = container.scrollWidth - container.clientWidth;
      setScrollProgress((scrollLeft / maxScrollLeft) * 100);
    }
  };

  return (
    <div className="w-full flex flex-col items-center bg-white text-darkblue py-12 my-10">
      {/* Centered Header */}
      <h2 className="text-5xl font-semibold mb-10 text-center">
        The Contracto AI OHSE Management Tool
      </h2>

      {/* Scrollable Card Section */}
      <div
        className="flex overflow-x-auto space-x-6 px-4 w-full max-w-7xl pb-4 scrollbar-hide"
        ref={cardContainerRef}
        onScroll={handleScroll}
        onMouseDown={handleMouseDown}
        onMouseLeave={handleMouseLeave}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
        style={{ cursor: isDragging.current ? 'grabbing' : 'grab' }}
      >
        {categories.map((category, index) => (
          <div
            key={index}
            className="min-w-[250px] lg:min-w-[200px] w-[450px] h-[400px] flex flex-col justify-start items-center bg-lightskyblue text-darkblue rounded-xl shadow-lg p-6 flex-shrink-0 transition-all duration-200 ease-in-out"
          >
            {/* SVG Icon */}
            <img src={category.icon} alt={`${category.title} icon`} className="w-16 h-16 mb-4" />
            
            {/* Title */}
            <h3 className="text-2xl font-semibold mb-2 text-center">
              {category.title}
            </h3>

            {/* Description */}
            <p className="text-lg text-semibold text-gray-700 text-center mb-4">
              {category.description}
            </p>

            {/* Button at Bottom */}
            {/* <div className="flex justify-center w-full mt-auto">
              <button className="bg-buttonBlue text-white px-6 py-2 rounded font-medium shadow-md hover:bg-blue-600">
                Learn More
              </button>
            </div> */}
          </div>
        ))}
      </div>

      {/* Horizontal Progress Bar */}
      <div className="relative w-full max-w-7xl mt-4 px-4">
        <div className="h-1 bg-gray-300 rounded-full overflow-hidden">
          <div
            className="h-1 bg-buttonBlue rounded-full transition-all duration-200"
            style={{ width: `${scrollProgress}%` }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default RiskCategory;
