import React from "react";
import { Link } from "react-router-dom";
import Contractologo from "../Assets/Contracto-logo.png";

const Header = () => {
  return (
    <div className="w-full flex flex-row lg:flex-row justify-between bg-white lg:justify-between items-start max-w-7xl mx-auto py-4 px-6 lg:px-14">
      {/* Logo */}
      <a href="https://thecontracto.com/" target="_blank" rel="noopener noreferrer">
        <img src={Contractologo} alt="Contracto" className="h-16 lg:h-20 my-2"  />
      </a>


      <div className="flex flex-row lg:flex-row items-center w-full lg:w-auto lg:ml-60 ml-20 space-y-4 lg:space-y-0 lg:space-x-20">
        {/* Navigation links */}
        <div className="flex items-center space-x-20 lg:ml-40 mr-10 my-10 lg:mr-20 text-md text-semibold">
          <a href="https://thecontracto.com/" target="_blank" rel="noopener noreferrer">
            <h4>Home</h4>
          </a>

          <a href="https://thecontracto.com/home-management/about-us/" target="_blank" rel="noopener noreferrer">
            <h4>About</h4>
          </a>
        </div>

        {/* Live Demo Button */}
        <Link to="/signin">
          <button className="bg-buttonBlue text-white px-6 py-2 my-10 rounded font-medium shadow-xl hover:bg-darkblue outline-none">
            Click Here for a Live Demo
          </button>
        </Link>
      </div>
    </div>
  );
};

export default Header;
