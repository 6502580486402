import React, { useState } from 'react';
import { Bar, Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement);

const Cameras = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 5;
  const totalPages = 3; // Example total pages; adjust as necessary
  const [selectedRows, setSelectedRows] = useState(new Set());

  // Placeholder data for the bar chart
  const barChartData = {
    labels: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
    datasets: [
      {
        label: 'Available Cameras',
        data: [40, 35, 38, 43, 48, 50, 29],
        backgroundColor: 'rgba(75,192,192,0.6)',
      },
      {
        label: 'Offline Cameras',
        data: [3, 2, 5, 6, 7, 8, 9],
        backgroundColor: 'rgba(255,99,132,0.6)',
      },
      {
        label: 'Online Cameras',
        data: [30, 27, 25, 25, 32, 27, 23],
        backgroundColor: 'rgba(54,162,235,0.6)',
      },
    ],
  };

  // Placeholder data for the pie chart (reason for offline cameras)
  const pieChartData = {
    labels: ['Network Issue', 'Power Failure', 'Maintenance', 'Other'],
    datasets: [
      {
        label: 'Offline Reasons',
        data: [40, 20, 30, 10], // Placeholder data, replace with actual data
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'],
      },
    ],
  };

  const barChartOptions = {
    responsive: true,
    maintainAspectRatio: false, // Allows height adjustment
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Camera Status Overview',
      },
    },
  };

  const pieChartOptions = {
    responsive: true,
    maintainAspectRatio: false, // Allows height adjustment
    plugins: {
      legend: {
        position: 'bottom',
      },
    },
  };

  const CameraTable = ({ title, data }) => {
    return (
      <div className="mb-8">
        <h3 className="text-2xl font-semibold text-darkblue mb-4">{title}</h3>
        
        {/* Pagination Control - Top */}
        <div className="flex justify-between items-center mb-4">
          <button className="bg-gray-200 w-20 h-10 rounded-md hover:bg-gray-300">Previous</button>
          <span className="text-gray-600">1 / 3</span>
          <button className="bg-gray-200 w-20 h-10 rounded-md hover:bg-gray-300">Next</button>
        </div>
  
        {/* Table */}
        <div className="overflow-auto bg-white shadow-lg rounded-lg">
          <table className="min-w-full bg-white">
            <thead className="bg-gray-100 border-b">
              <tr>
                <th className="py-4 px-6 text-left">
                  <input type="checkbox" className="bg-gray-200 rounded-md" />
                </th>
                <th className="py-4 px-6 text-left text-md font-bold text-gray-600 uppercase">Name</th>
                <th className="py-4 px-6 text-left text-md font-bold text-gray-600 uppercase">Location</th>
                <th className="py-4 px-6 text-left text-md font-bold text-gray-600 uppercase">Status</th>
                <th className="py-4 px-6 text-left text-md font-bold text-gray-600 uppercase">Health</th>
                <th className="py-4 px-6 text-left text-md font-bold text-gray-600 uppercase">Reason</th>
                <th className="py-4 px-6 text-left text-md font-bold text-gray-600 uppercase">Collisions Reported</th>
              </tr>
            </thead>
            <tbody>
              {data.map((camera, index) => (
                <tr key={index} className="border-b hover:bg-gray-50">
                  <td className="py-4 px-6">
                    <input type="checkbox" className="bg-gray-200 rounded-md" />
                  </td>
                  <td className="py-4 px-6 text-md text-gray-700">{camera.name}</td>
                  <td className="py-4 px-6 text-md text-gray-700">{camera.location}</td>
                  <td className="py-4 px-6 text-md text-gray-700">{camera.status}</td>
                  <td className="py-4 px-6 text-md text-gray-700">{camera.health}</td>
                  <td className="py-4 px-6 text-md text-gray-700">{camera.reason}</td>
                  <td className="py-4 px-6 text-md text-gray-700">{camera.collisionsReported}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
  
        {/* Pagination Control - Bottom */}
        <div className="flex justify-between items-center mt-5">
          <button className="bg-gray-200 w-20 h-10 rounded-md hover:bg-gray-300">Previous</button>
          <span className="text-gray-600">1 / 3</span>
          <button className="bg-gray-200 w-20 h-10 rounded-md hover:bg-gray-300">Next</button>
        </div>
      </div>
    );
  };

  return (
    <div className="p-8">
      {/* Visualization Section */}
      <div className="flex space-x-4 mb-8">
        {/* Bar Chart Container - 60% Width */}
        <div className="w-3/5 bg-white pt-8 pb-20 px-5 shadow-lg rounded-lg h-96"> {/* Set height here */}
          <h2 className="text-2xl font-semibold text-darkblue mb-6">Camera Status Overview</h2>
          <Bar data={barChartData} options={barChartOptions} height={150} />
        </div>

        {/* Pie Chart Container - 40% Width */}
        <div className="w-2/5 bg-white pt-8 pb-20 px-5 shadow-lg rounded-lg h-96"> {/* Set height here */}
          <h2 className="text-2xl font-semibold text-darkblue mb-6">Offline Camera Reasons</h2>
          <Pie data={pieChartData} options={pieChartOptions} height={150} />
        </div>
      </div>

      {/* Tables for Offline, Online, and Available Cameras */}
      <CameraTable title="Offline Cameras" data={[
        { name: 'Camera 1', location: 'Location A', status: 'Offline', health: 'Poor', reason: 'Network Issue', collisionsReported: 2 },
        { name: 'Camera 2', location: 'Location B', status: 'Offline', health: 'Fair', reason: 'Power Failure', collisionsReported: 1 },
        // Add more offline cameras as needed
      ]} />

      <CameraTable title="Online Cameras" data={[
        { name: 'Camera 3', location: 'Location C', status: 'Online', health: 'Good', reason: 'N/A', collisionsReported: 4 },
        { name: 'Camera 4', location: 'Location D', status: 'Online', health: 'Good', reason: 'N/A', collisionsReported: 6 },
        // Add more online cameras as needed
      ]} />

      <CameraTable title="Available Cameras" data={[
        { name: 'Camera 1', location: 'Location A', status: 'Offline', health: 'Poor', reason: 'Network Issue', collisionsReported: 2 },
        { name: 'Camera 2', location: 'Location B', status: 'Offline', health: 'Fair', reason: 'Power Failure', collisionsReported: 1 },
        { name: 'Camera 3', location: 'Location C', status: 'Online', health: 'Good', reason: 'N/A', collisionsReported: 4 },
        { name: 'Camera 4', location: 'Location D', status: 'Online', health: 'Good', reason: 'N/A', collisionsReported: 6 },
        { name: 'Camera 5', location: 'Location E', status: 'Available', health: 'Excellent', reason: 'N/A', collisionsReported: 5 },
        { name: 'Camera 6', location: 'Location F', status: 'Available', health: 'Good', reason: 'N/A', collisionsReported: 2 },
        // Add more available cameras as needed
      ]} />
    </div>
  );
};

export default Cameras;
