import React from "react";
// import Connectorlink from "../Assets/Connector-link.svg";
// import Connectorlinkleft from "../Assets/Coonectorlinkleft.svg";
import GreentickIcon from "../Assets/greentickicon.svg";

import Cameraintegrationboard from "../Assets/cameraintegrationboard.png"; // Replace with the actual path to your GIF
import Cameraintegration from "../Assets/cameraintegration.gif";
import Cameraintegrationaccess from "../Assets/cameraintegrationaccess.svg";

import CustomSafety from "../Assets/customsafety.gif";
import CustomSafetyrules from "../Assets/customsafetyrules.gif";
import CustomSafetyBoard from "../Assets/areadetectionrules.png";

import Filterevents from "../Assets/filterevents.gif";
import Filterevents1 from "../Assets/filterevents1.svg";
// import Filterevents2 from "../Assets/filterevents2.svg";
// import Filterevents3 from "../Assets/Filterevents3.jpg";

import Dashboardicon from "../Assets/dashboardicon.svg";
import Dashboardanalytics from "../Assets/dashboardanalytics.png";
import Dashboardcapability from "../Assets/dashboardcapability.gif";

import Correctiveactions from "../Assets/correctiveactions.png";
// import Correctiveactions1 from "../Assets/correctiveaction1.gif";
// import Correctiveactionsicon from "../Assets/correctiveactionicon.svg";


const ProcessFramework = () => {
    return (
      <div className="w-full flex flex-col items-center">
     {/* Section 1 */}
<section className="w-full flex flex-col items-center bg-gradient-to-r from-orange to-skyblue py-16 lg:py-18 relative">
  <div className="flex flex-col lg:flex-row items-center justify-between w-full max-w-7xl px-6 lg:px-12">
    {/* Left Image Section */}
    <div className="relative lg:w-[60%] w-full h-[400px] flex justify-center lg:justify-start mr-20">
      <img
        src={Cameraintegrationboard}
        alt="Camera Dashboard"
        className="w-[1000px] h-[400px] rounded-xl shadow-lg object-fit"
      />
    </div>


    {/* Right Content Section */}
    <div className="mt-10 lg:mt-0 lg:w-[40%] w-full text-left space-y-6">
      <h2 className="text-4xl lg:text-5xl font-bold text-darkblue">
        Seamless Camera Integration
      </h2>
      <p className="text-lg lg:text-xl text-gray-700 max-w-lg">
        Our vision processing unit seamlessly integrates with your local network and connects to your cameras, enabling on-site data processing and storage.
      </p>

      <ul className="space-y-4 text-lg">
        <li className="flex items-center space-x-3">
          <img src={GreentickIcon} alt="Check" className="w-6 h-6 lg:w-8 lg:h-8" />
          <span>Automatic Camera Detection</span>
        </li>
        <li className="flex items-center space-x-3">
          <img src={GreentickIcon} alt="Check" className="w-6 h-6 lg:w-8 lg:h-8" />
          <span>Compatible with All Modern Camera Systems</span>
        </li>
        <li className="flex items-center space-x-3">
          <img src={GreentickIcon} alt="Check" className="w-6 h-6 lg:w-8 lg:h-8" />
          <span>Secure On-Premises Processing</span>
        </li>
        <li className="flex items-center space-x-3">
          <img src={GreentickIcon} alt="Check" className="w-6 h-6 lg:w-8 lg:h-8" />
          <span>Plug-and-Play Installation</span>
        </li>
      </ul>
    </div>
  </div>
</section>


{/* Section 2 with Background Image and CustomSafety GIF */}
<section className="w-full flex flex-col items-center py-16 lg:py-24 bg-gradient-to-r from-orange to-skyblue relative"> {/* Add relative here */}
    <div className="flex flex-col lg:flex-row items-start lg:items-center justify-between w-full max-w-7xl px-6 lg:px-12">
        {/* Left Column with Background Image and Overlayed CustomSafety GIF */}
        <div className="relative lg:w-[60%] w-full flex justify-center lg:justify-start">
            {/* Background Image */}
            <div className="relative w-[800px] h-[420px] flex justify-center lg:justify-center mr-20">
            <img
              src={CustomSafetyBoard}
              alt="Reports Dashboard"
              className="w-full h-auto rounded-xl shadow-lg object-fit"
            />
          </div>
            {/* CustomSafety GIF on top of Background */}
            {/* <img
                src={CustomSafetyrules}
                alt="Custom Safety Overlay"
                className="absolute w-[520px] h-[520px] top-0 left-5"
            />
            <img
                src={CustomSafety}
                alt="Custom Safety Overlay"
                className="absolute top-40 bottom-10 -right-2 shadow-lg w-30 h-40 rounded-lg"
            /> */}
        </div>

        {/* Right Column with Text Content */}
        <div className="mt-10 lg:mt-0 lg:w-[45%] w-full text-left space-y-6">
            <h2 className="text-5xl lg:text-5xl font-bold text-darkblue">
              Mark your sites with customized OHSE Strategy
            </h2>
            <p className="text-xl text-gray-700 max-w-lg">
                Our AI-powered safety software empowers you to apply your EHS expertise, training your cameras to recognize risks and hazards specific to your facility.            </p>

            <ul className="space-y-4 text-lg text-semibold">
                <li className="flex items-center space-x-3">
                    <img src={GreentickIcon} alt="Check" className="w-8 h-8" />
                    <span>Identify and arrange Go and No-Go zones in your site</span>
                </li>
                <li className="flex items-center space-x-3">
                    <img src={GreentickIcon} alt="Check" className="w-8 h-8" />
                    <span>Differentiate between Green, Amber and Red marked areas</span>
                </li>
                <li className="flex items-center space-x-3">
                    <img src={GreentickIcon} alt="Check" className="w-8 h-8" />
                    <span>Clear visibility on the location and reach of Cameras at site</span>
                </li>
                <li className="flex items-center space-x-3">
                    <img src={GreentickIcon} alt="Check" className="w-8 h-8" />
                    <span>Intuitive and Easy to Use</span>
                </li>
            </ul>
        </div>
    </div>

        {/* Connector positioned with improved visibility
        <div className="absolute w-full -bottom-6 flex justify-center z-20 transform translate-y-1/2">
          <img
            src={Connectorlink}
            alt="Connector to Next Section"
            className="w-full max-w-md"
            style={{ opacity: 0.8 }}
          />
        </div> */}
</section>

    {/*New Section: Digest & Filter Events
<section className="w-full flex flex-col items-center py-16 my-10 lg:py-24 bg-white relative">
    <div className="flex flex-col lg:flex-row items-start lg:items-center justify-between w-full max-w-7xl px-6 lg:px-12">
        {/* Left Column with Text Content 
        <div className="lg:w-[45%] w-full text-left space-y-6">
            <h2 className="text-5xl lg:text-5xl font-bold text-darkblue">
                Streamlined Event Review and Filtering
            </h2>
            <p className="text-xl text-gray-700 max-w-lg">
                Our intuitive event log simplifies the review of non-compliant activities across your workplace.     Our intuitive event log allows the user to seamlessly review non-compliant events across your entire workplace.
            </p>

            <ul className="space-y-4 text-lg text-semibold">
                <li className="flex items-center space-x-3">
                    <img src={GreentickIcon} alt="Check" className="w-8 h-8" />
                    <span>Filter Events by Camera, Tag, and Rule</span>
                </li>
                <li className="flex items-center space-x-3">
                    <img src={GreentickIcon} alt="Check" className="w-8 h-8" />
                    <span>Effortlessly Share Events with Team Members</span>
                </li>
                <li className="flex items-center space-x-3">
                    <img src={GreentickIcon} alt="Check" className="w-8 h-8" />
                    <span>Batch Actions for Event Management</span>
                </li>
                <li className="flex items-center space-x-3">
                    <img src={GreentickIcon} alt="Check" className="w-8 h-8" />
                    <span>Custom Event Tagging</span>
                </li>
            </ul>
        </div>

         Right Column with Images 
        <div className="relative lg:w-[50%] w-full flex justify-center lg:justify-end mt-10 lg:mt-0">
            {/* Main Event Log Image 
            <div className="relative w-full lg:max-w-md bg-white shadow-lg rounded-lg">
                <img src={Filterevents} alt="Events Log" className="w-full h-auto" />

                 Overlays for Additional Content 
                {/* <img
                    src={Filterevents2}
                    alt="Filter Icon Overlay"
                    className="absolute bottom-[80px] right-[50px] shadow-lg z-10"
                    style={{ bottom: '80px',right: '150px' }}
                /> 
                <img
                    src={Filterevents1}
                    alt="Events GIF Overlay"
                    className="absolute top-40 left-[-30px] w-[120px] h-[200px] shadow-lg rounded-lg bg-transparent z-10"
                />
                {/* <img
                    src={Filterevents3}
                    alt="Event Card Overlay"
                    className="absolute top-20 right-[0px] lg:right-[-50px] shadow-lg w-[140px] h-[160px] rounded-lg bg-darkblue text-white p-4 flex flex-col justify-center items-center z-10"
                /> 
            </div>
        </div>
    </div>

 Connector positioned at the bottom of the section
    <div className="absolute w-full bottom-[-80px] left-1/2 transform -translate-x-1/2 translate-y-1/2 z-20 flex justify-center">
        <img
            src={Connectorlinkleft}
            alt="Connector to Next Section"
            className="w-full max-w-md"
            style={{ opacity: 0.8 }}
        />
    </div> 
</section> */}



{/* Powerful Reporting & Dashboard Capabilities */}
<section className="w-full flex flex-col items-center py-16 lg:py-24 bg-gradient-to-r from-orange to-skyblue relative">
    <div className="flex flex-col lg:flex-row items-start lg:items-center justify-between w-full max-w-7xl px-6 lg:px-12">
        {/* Left Column with Text Content */}
        <div className="lg:w-[40%] w-full text-left space-y-6">
            <h2 className="text-5xl lg:text-5xl font-bold text-darkblue">
              Data Insights and Decision Intelligence
            </h2>
            <p className="text-xl text-gray-700 max-w-lg">
                Create detailed safety audit reports to complement your manual audits, bringing deeper insights to your health and safety discussions.            </p>

            <ul className="space-y-4 text-lg text-semibold">
                <li className="flex items-center space-x-3">
                    <img src={GreentickIcon} alt="Check" className="w-8 h-8" />
                    <span>Customizable dash boards</span>
                </li>
                <li className="flex items-center space-x-3">
                    <img src={GreentickIcon} alt="Check" className="w-8 h-8" />
                    <span>Statutory compliance reports</span>
                </li>
                <li className="flex items-center space-x-3">
                    <img src={GreentickIcon} alt="Check" className="w-8 h-8" />
                    <span>Seamless communication amongst team members</span>
                </li>
                <li className="flex items-center space-x-3">
                    <img src={GreentickIcon} alt="Check" className="w-8 h-8" />
                    <span>Task Management</span>
                </li>
            </ul>
        </div>

        {/* Right Column with Dashboard Images */}
        <div className="relative lg:w-[60%] w-full flex justify-center lg:justify-end mt-10 lg:mt-0">
          {/* Main Dashboard Image */}
          <div className="relative w-[800px] h-[420px] flex justify-center lg:justify-center ml-20">
            <img
              src={Dashboardanalytics}
              alt="Reports Dashboard"
              className="w-full h-auto rounded-xl shadow-lg object-fit"
            />
          </div>

          {/* Optional Overlayed Elements */}
          {/* <img
              src={Dashboardanalytics}
              alt="Analytics Overlay"
              className="absolute top-10 w-[140px] h-[180px] shadow-lg rounded-lg z-10"
              style={{ right: '-90px' }}
          />
          <img
              src={Dashboardicon}
              alt="Dashboard Icon Overlay"
              className="absolute w-[100px] h-[140px] rounded-lg z-10"
              style={{ right: '-90px', top: '220px' }}
          /> */}
        </div>

    </div>

    {/* Connector positioned at the bottom of the section
    <div className="absolute w-full bottom-[-50px] left-1/2 transform -translate-x-1/2 translate-y-1/2 z-20 flex justify-center">
        <img
            src={Connectorlinkleft}
            alt="Connector to Next Section"
            className="w-full max-w-md"
            style={{ opacity: 0.8 }}
        />
    </div> */}
</section>



    {/*Section: Implement Corrective Actions */}
      <section className="w-full flex flex-col items-center py-16 lg:py-24 bg-gradient-to-r from-orange to-skyblue relative">
        <div className="flex flex-col lg:flex-row items-start lg:items-center justify-between w-full max-w-7xl px-6 lg:px-12">

          {/* Left Column with Text Content */}
          <div className="mt-10 mr-20 lg:mt-0 lg:w-[45%] w-full text-left space-y-6">
            <h2 className="text-5xl lg:text-5xl font-bold text-darkblue">
            Implement Effective Corrective Actions
            </h2>
            <p className="text-xl text-gray-700 max-w-lg">
            Our storyboard feature enables EHS teams to collaborate on safety event insights and take corrective action.            </p>

            <ul className="space-y-4 text-lg text-semibold">
              <li className="flex items-center space-x-3">
                <img src={GreentickIcon} alt="Check" className="w-8 h-8" />
                <span>Automate Email Workflows for Seamless Follow-Up</span>
              </li>
              <li className="flex items-center space-x-3">
                <img src={GreentickIcon} alt="Check" className="w-8 h-8" />
                <span>Easily Share with Stakeholders</span>
              </li>
              <li className="flex items-center space-x-3">
                <img src={GreentickIcon} alt="Check" className="w-8 h-8" />
                <span>Group Events by Common Themes</span>
              </li>
              <li className="flex items-center space-x-3">
                <img src={GreentickIcon} alt="Check" className="w-8 h-8" />
                <span>Attach Documents, Actions, and Comments</span>
              </li>
            </ul>
          </div>

   {/* Right Column with Dashboard Images */}
   <div className="relative lg:w-[60%] w-full flex justify-center lg:justify-end mt-10 lg:mt-0">
          {/* Main Dashboard Image */}
          <div className="relative w-[900px] h-[380px] flex justify-center lg:justify-center ml-20">
            <img
              src={Correctiveactions}
              alt="Reports Dashboard"
              className="w-[900px] h-[380px] rounded-xl shadow-lg object-fit"
            />
          </div>
        </div>
        </div>
      </section>

      </div>
    );
  };

export default ProcessFramework;
