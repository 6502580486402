import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar';
import Navbar from './Navbar';

const DashboardLayout = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="h-screen flex">
      {/* Sidebar */}
      <div
        className={`h-full transition-all duration-300 fixed top-0 left-0 z-20 ${
          isSidebarOpen ? 'w-55' : 'w-20'
        }`}
      >
        <Sidebar isCollapsed={!isSidebarOpen} />
      </div>

      {/* Main Content Area */}
      <div
        className="flex-grow flex flex-col"
        style={{
          marginLeft: isSidebarOpen ? '220px' : '80px', // Dynamically adjust content margin based on sidebar state
          transition: 'margin-left 0.3s ease',
        }}
      >
        {/* Navbar */}
        <div className="fixed top-0 left-0 right-0 z-10">
          <Navbar onToggleSidebar={toggleSidebar} isCollapsed={!isSidebarOpen} />
        </div>

        {/* Main Content */}
        <div className="mt-16 p-6 bg-gray-100 flex-grow overflow-y-auto">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
