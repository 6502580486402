import React from "react";
import AreacontrolVideo from "../Assets/herovideo.mp4"; // Replace this with your actual video file
// import administration from "../Assets/Administration_floor.jpeg";
// import areamap from "../Assets/area_map.png";
// import contractologo from "../Assets/Contracto.png";

const HeroSection = () => {
  return (
    <section className="w-full bg-gradient-to-r from-orange to-skyblue text-white py-20 px-4">
      <div className="max-w-7xl mx-auto flex flex-col lg:flex-row items-start justify-between lg:space-x-16">
        
        {/* Left column with text content and buttons */}
        <div className="lg:w-[45%] min-w-[300px] space-y-6 text-left">
          <h1 className="text-5xl font-bold leading-tight text-darkblue">
            The Contracto - AI enabled site monitoring system for OHSE 
          </h1>
          <p className="text-lg max-w-md text-darkblue">
            Monitors workers on the usage of proper safety gear, observes intrusion into restricted areas, safety hazards and environmental hazards - powered by Machine Vision. </p>
          {/* <div className="flex space-x-4">
            <button className="bg-buttonBlue text-white px-6 py-2 rounded font-medium shadow-md hover:bg-blue-600">
              Watch Video
            </button>
            <button className="border border-buttonBorder text-white px-6 py-2 rounded font-medium shadow-md hover:bg-blue-700">
              Book Demo
            </button>
          </div> */}
        </div>

        {/* Right column with video */}
        <div className="lg:w-[55%] flex justify-center lg:justify-end mt-8 lg:mt-0">
          <video 
            src={AreacontrolVideo} 
            controls 
            autoPlay 
            loop 
            muted
            className="w-2xl max-w-2xl rounded-md shadow-lg" // Increased width, removed rounded corners
            alt="Sample video"
          />
        </div>
      </div>
      <div className="w-full flex justify-center">
      {/* <div className="bg-white rounded-2xl py-6 px-8 shadow-lg max-w-7xl w-full mx-40 mb-0 lg:mx-auto mt-20">
        <div className="flex justify-between items-center">
          <img src={administration} alt="M&S" className="h-12" />
          <img src={areamap} alt="Telus" className="h-12" />
          <img src={contractologo} alt="Contracto" className="h-12" />
        </div>
      </div> */}
    </div>
    </section>
    
  );
};

export default HeroSection;
