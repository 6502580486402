import React, { useState } from 'react';

const API_BASE_URL = 'https://api-e5tzoauvmq-uc.a.run.app';

const ViewActionsPanel = ({ card, onClose, updateCard, moveCard }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedCard, setEditedCard] = useState({ ...card });
  const [actionItems, setActionItems] = useState([]); // Action items state
  const [newActionItem, setNewActionItem] = useState(''); // New action item input

  // Update edited card details
  const handleInputChange = (field, value) => {
    setEditedCard((prev) => ({ ...prev, [field]: value }));
  };

  const handleSave = async () => {
    try {
      // Only include due_date if it's valid
      const validDueDate = editedCard.dueDate ? editedCard.dueDate : null;

      // Send updated details to the database
      const response = await fetch(`${API_BASE_URL}/api/database/update-action`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          id: editedCard.id,
          title: editedCard.title,
          description: editedCard.description,
          priority: editedCard.priority,
          due_date: validDueDate,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to update action in the database.');
      }

      const result = await response.json();
      console.log('Action updated:', result);

      updateCard(editedCard); // Update card in the UI
      setIsEditing(false);
    } catch (error) {
      console.error('Error saving updates:', error.message);
      alert('Failed to save updates. Please try again.');
    }
  };

  // Add a new action item
  const addActionItem = () => {
    if (newActionItem.trim() !== '') {
      setActionItems([...actionItems, { text: newActionItem, completed: false }]);
      setNewActionItem('');
    }
  };

  // Toggle the completed status of an action item
  const toggleActionItem = (index) => {
    const updatedItems = [...actionItems];
    updatedItems[index].completed = !updatedItems[index].completed;
    setActionItems(updatedItems);
  };

  return (
    <div className="flex flex-col fixed right-0 top-[55px] h-[calc(100%-55px)] w-full sm:w-2/3 md:w-1/2 lg:w-2/5 bg-gray-50 shadow-lg rounded-xl p-8 space-y-8 overflow-y-auto">
      {/* Close Button */}
      <button onClick={onClose} className="absolute top-4 left-4 text-gray-400 hover:text-gray-600">
        ✖
      </button>

      {/* Header */}
      <h2 className="text-2xl font-bold text-center text-darkblue pb-2">View Action</h2>

      {/* Media Section */}
      <div className="w-full h-[450px] bg-gray-200 rounded-lg">
        {card.media_url ? (
          card.type === 'image' ? (
            <a href={card.media_url} target="_blank" rel="noopener noreferrer">
              <img
                src={card.media_url}
                alt="Media Preview"
                className="w-full h-full object-contain"
              />
            </a>
          ) : card.type === 'video' ? (
            <a href={card.media_url} target="_blank" rel="noopener noreferrer">
              <video
                src={card.media_url}
                className="w-full h-full object-cover"
                controls
              />
            </a>
          ) : card.type === 'audio' ? (
            <a href={card.media_url} target="_blank" rel="noopener noreferrer">
              <audio
                src={card.media_url}
                className="w-full h-full"
                controls
              />
            </a>
          ) : card.type === 'document' ? (
            <iframe
              src={card.media_url}
              className="w-full h-full rounded-md border border-gray-300"
              title="Document Preview"
              frameBorder="0"
            />
          ) : (
            <a href={card.media_url} target="_blank" rel="noopener noreferrer">
              <div className="text-2xl text-gray-500">📎 Other</div>
            </a>
          )
        ) : (
          <div className="text-6xl text-gray-500">No Media</div>
        )}
      </div>


      {/* Card ID and Title */}
      <div>
        <h3 className="text-sm font-semibold text-gray-700 mb-1">Card ID: {card.id}</h3>
        {isEditing ? (
          <input
            type="text"
            value={editedCard.title}
            onChange={(e) => handleInputChange('title', e.target.value)}
            className="w-full p-2 border-b border-gray-300 bg-transparent focus:outline-none focus:border-skyblue"
          />
        ) : (
          <h1 className="text-lg font-bold text-gray-900">{card.title}</h1>
        )}
      </div>

      {/* Priority and Dates */}
      <div className="flex flex-wrap gap-6">
        <div className="flex-1">
          <h3 className="text-sm font-semibold text-gray-700 mb-2">Priority</h3>
          {isEditing ? (
            <select
              value={editedCard.priority}
              onChange={(e) => handleInputChange('priority', e.target.value)}
              className={`w-full p-2 border border-gray-300 rounded-xl text-white focus:outline-none focus:border-skyblue ${
                editedCard.priority === 'urgent'
                  ? 'bg-red'
                  : editedCard.priority === 'high'
                  ? 'bg-orange'
                  : editedCard.priority === 'medium'
                  ? 'bg-yellow'
                  : editedCard.priority === 'low'
                  ? 'bg-green'
                  : 'bg-gray-800'
              }`}
            >
              <option value="urgent" className="bg-red text-white">Urgent</option>
              <option value="high" className="bg-orange text-white">High</option>
              <option value="medium" className="bg-yellow text-black">Medium</option>
              <option value="low" className="bg-green text-white">Low</option>
            </select>
          ) : (
            <span
              className={`inline-block px-3 py-1 text-sm font-semibold text-white rounded-full ${
                card.priority === 'urgent'
                  ? 'bg-red'
                  : card.priority === 'high'
                  ? 'bg-orange'
                  : card.priority === 'medium'
                  ? 'bg-yellow text-black'
                  : card.priority === 'low'
                  ? 'bg-green'
                  : 'bg-gray-800'
              }`}
            >
              {card.priority}
            </span>
          )}
        </div>

        <div className="flex-1">
          <h3 className="text-sm font-semibold text-gray-700 mb-2">Created Date</h3>
          <p className="text-gray-800">{card.createdDate}</p>
        </div>

        <div className="flex-1">
          <h3 className="text-sm font-semibold text-gray-700 mb-2">Due Date</h3>
          {isEditing ? (
            <input
              type="date"
              value={editedCard.dueDate}
              onChange={(e) => handleInputChange('dueDate', e.target.value)}
              className="w-full p-2 border-b border-gray-300 bg-transparent focus:outline-none focus:border-skyblue"
            />
          ) : (
            <p className="text-gray-800">{card.dueDate}</p>
          )}
        </div>
      </div>

      {/* Description */}
      <div>
        <h3 className="text-sm font-semibold text-gray-700 mb-1">Description</h3>
        {isEditing ? (
          <textarea
            value={editedCard.description}
            onChange={(e) => handleInputChange('description', e.target.value)}
            className="w-full border-b border-gray-300 bg-transparent focus:outline-none focus:border-skyblue resize-none"
            rows="3"
          ></textarea>
        ) : (
          <p className="text-gray-800">{card.description}</p>
        )}
      </div>

      {/* Action Items */}
      <h3 className="text-sm font-semibold text-gray-700 mb-1">Action Items</h3>
      <div className="overflow-y-auto px-5 pb-10">
        <div className="space-y-2">
          {actionItems.map((item, index) => (
            <div key={index} className="flex items-center space-x-3">
              <input
                type="checkbox"
                checked={item.completed}
                onChange={() => toggleActionItem(index)}
                className="w-4 h-4 text-blue-500 focus:ring-blue-400 border-gray-300 rounded"
              />
              <span
                className={`text-sm ${
                  item.completed ? 'line-through text-gray-500' : 'text-gray-800'
                }`}
              >
                {item.text}
              </span>
            </div>
          ))}
        </div>
        <div className="flex items-center space-x-3 mt-3">
          <input
            type="text"
            value={newActionItem}
            onChange={(e) => setNewActionItem(e.target.value)}
            placeholder="Add new action item"
            className="flex-1 p-2 border border-bottom border-gray-200 bg-transparent rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
          />
          <button
            onClick={addActionItem}
            className="bg-blue-500 text-white px-4 py-2 rounded-md shadow-md hover:bg-blue-600"
          >
            Add
          </button>
        </div>
      </div>

      {/* Buttons */}
      <div className="flex gap-4 pt-10">
        <button
          onClick={() => moveCard(card.id, card.column, 'reviewed')}
          className="flex-1 bg-darkblue text-white px-4 py-2 rounded-md shadow-md hover:bg-blue-600"
        >
          Reviewed
        </button>
        <button
          onClick={() => moveCard(card.id, card.column, 'archived')}
          className="flex-1 bg-gray-700 text-white px-4 py-2 rounded-md shadow-md hover:bg-gray-600"
        >
          Archive
        </button>
        <button
          onClick={() => moveCard(card.id, card.column, 'completed')}
          className="flex-1 bg-green text-white px-4 py-2 rounded-md shadow-md hover:bg-green-600"
        >
          Completed
        </button>
        <button
          onClick={() => setIsEditing(!isEditing)}
          className="flex-1 bg-orange text-white px-4 py-2 rounded-md shadow-md hover:bg-orange-600"
        >
          {isEditing ? 'Cancel' : 'Edit'}
        </button>
        <button
          onClick={handleSave}
          className="flex-1 bg-green text-white px-4 py-2 rounded-md shadow-md hover:bg-blue-600"
          disabled={!isEditing}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default ViewActionsPanel;
