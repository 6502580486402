import React from "react";

const Footer = () => {
  return (
    <div className="w-full bg-footerblue text-white py-8 h-500">
      {/* Footer Content Container */}
      {/* Divider Line */}
      <div className="mt-4 w-full flex justify-center">
        <hr className="border-t border-gray-600 w-8/12 lg:w-full mx-10" />
      </div>
      <div className="max-w-7xl flex flex-col lg:flex-row items-start text-sm space-y-4 lg:space-y-0 px-6 mx-h-100">
        {/* Left Side: Copyright */}
        <span className="text-start lg:text-left mt-5 ml-5">
          Copyright © 2024 Contracto AI
        </span>
      </div>
    </div>
  );
};

export default Footer;
