import React, { useState, useEffect } from 'react';
import { Pie, Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import AddEmployeePanel from '../Widgets/AddEmployeePanel';

const API_BASE_URL = 'https://api-e5tzoauvmq-uc.a.run.app';

const UserProfile = () => {
  const departments = ["Safety Inspection", "Environmental Safety", "Operations"];
  const [employees, setEmployees] = useState([]); // Dynamically loaded employees
  const [selectedDepartment, setSelectedDepartment] = useState("All Departments");
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchEmployees();
  }, []);

  // Fetch employees from backend
  const fetchEmployees = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${API_BASE_URL}/api/database/fetch-employee-data`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log('Fetched Employees:', data.data); // Debug log
      if (data.success) {
        setEmployees(data.data);
        setSelectedEmployee(data.data.length > 0 ? data.data[0] : null);
      } else {
        setEmployees([]);
        setSelectedEmployee(null);
      }
    } catch (err) {
      console.error('Error fetching employees:', err);
      setEmployees([]);
      setSelectedEmployee(null);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDepartmentSelect = (e) => {
    setSelectedDepartment(e.target.value);
    const filtered = employees.filter((emp) => emp.department === e.target.value);
    setSelectedEmployee(filtered.length > 0 ? filtered[0] : null);
  };

  const handleEmployeeSelect = (e) => {
    const employee = employees.find((emp) => emp.id === Number(e.target.value));
    setSelectedEmployee(employee || null);
  };

  const handleAddEmployee = async (newEmployee) => {
    try {
      const response = await fetch(`${API_BASE_URL}/add-employee`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newEmployee),
      });

      const data = await response.json();
      console.log('Add Employee Response:', data); // Debug log

      if (data.success) {
        alert('Employee added successfully!');
        fetchEmployees(); // Refresh employee list
        setIsPanelOpen(false); // Close the panel
      } else {
        console.error('Failed to add employee:', data.message);
      }
    } catch (err) {
      console.error('Error adding employee:', err);
      alert('Failed to add employee.');
    }
  };

  const filteredEmployees =
    selectedDepartment === "All Departments"
      ? employees
      : employees.filter((emp) => emp.department === selectedDepartment);

  const otherIncidents = selectedEmployee
    ? selectedEmployee.stats?.totalIncidents -
      (selectedEmployee.stats?.nearMisses + selectedEmployee.stats?.severeIncidents)
    : 0;

  const pieData = selectedEmployee?.stats
    ? {
        labels: ["Other Incidents", "Near Misses", "Severe Incidents"],
        datasets: [
          {
            data: [
              otherIncidents,
              selectedEmployee.stats?.nearMisses,
              selectedEmployee.stats?.severeIncidents,
            ],
            backgroundColor: ["#42a5f5", "#ffcc00", "#ff6347"],
            hoverOffset: 4,
          },
        ],
      }
    : null;

  const barData = selectedEmployee?.stats
    ? {
        labels: ["Warehouse", "Factory Floor", "Parking Area"],
        datasets: [
          {
            label: "Incidents",
            data: Object.values(selectedEmployee.stats?.incidentTrends || {}),
            backgroundColor: ["#42a5f5", "#66bb6a", "#ffb74d"],
            borderRadius: 5,
          },
        ],
      }
    : null;

  return (
    <div className="p-8 bg-gray-100 min-h-screen">
      <div className="flex justify-between mb-6">
        <div className="flex space-x-4 w-3/4">
          <select
            onChange={handleDepartmentSelect}
            value={selectedDepartment}
            className="p-3 border border-gray-300 rounded-md bg-white shadow-md w-1/3"
          >
            <option value="All Departments">All Departments</option>
            {departments.map((dept, index) => (
              <option key={index} value={dept}>
                {dept}
              </option>
            ))}
          </select>
          <select
            onChange={handleEmployeeSelect}
            className="p-3 border border-gray-300 rounded-md bg-white shadow-md w-3/5"
          >
            {filteredEmployees.map((emp) => (
              <option key={emp.id} value={emp.id}>
                {emp.first_name} {emp.last_name}
              </option>
            ))}
          </select>
        </div>
        <button
          onClick={() => setIsPanelOpen(true)}
          className="bg-blue-600 text-white px-6 py-2 rounded-lg shadow-md hover:bg-blue-700"
        >
          + Add Employee
        </button>
      </div>

      {isLoading ? (
        <div className="text-center text-gray-500">Loading employees...</div>
      ) : (
        <div className="grid grid-cols-3 gap-6">
          <div className="col-span-2 bg-white rounded-lg shadow-lg p-6">
            <div className="flex space-x-6 items-center">
              <div className="w-24 h-24 bg-gray-300 rounded-full flex items-center justify-center overflow-hidden">
                {selectedEmployee?.profile_picture_url ? (
                  <img
                    src={selectedEmployee.profile_picture_url}
                    alt={selectedEmployee.first_name}
                    className="object-cover w-full h-full"
                  />
                ) : (
                  <span className="text-gray-500 text-2xl">👤</span>
                )}
              </div>
              <div>
                <h2 className="text-2xl font-bold text-gray-800">
                  {selectedEmployee
                    ? `${selectedEmployee.first_name} ${selectedEmployee.last_name}`
                    : "No Employee Selected"}
                </h2>
                {selectedEmployee && (
                  <p className="text-md text-gray-500">ID: {selectedEmployee.id}</p>
                )}
              </div>
            </div>

            {selectedEmployee && (
              <div className="mt-6 space-y-4">
                <div>
                  <h3 className="font-semibold text-gray-600 text-xl">Department:</h3>
                  <p className="text-gray-800 text-lg">{selectedEmployee.department}</p>
                </div>

                <div>
                  <h3 className="font-semibold text-gray-600 text-xl">Duties:</h3>
                  <ul className="list-disc list-inside text-gray-800 text-lg">
                    {Array.isArray(selectedEmployee.duties) ? (
                      selectedEmployee.duties.map((duty, index) => <li key={index}>{duty}</li>)
                    ) : (
                      <p>Duties information unavailable or not in array format.</p>
                    )}
                  </ul>
                </div>

                <div>
                  <h3 className="font-semibold text-gray-600 text-xl">Area:</h3>
                  <p className="text-gray-800 text-lg">{selectedEmployee.area}</p>
                </div>

                <div>
                  <h3 className="font-semibold text-gray-600 text-xl">Contact Details:</h3>
                  <p className="text-gray-800 text-lg">Government ID: {selectedEmployee.government_id}</p>
                  <p className="text-gray-800 text-lg">Phone: {selectedEmployee.phone}</p>
                  <p className="text-gray-800 text-lg">Email: {selectedEmployee.email}</p>
                  <p className="text-gray-800 text-lg">Address: {selectedEmployee.address}</p>
                </div>
              </div>
            )}
          </div>

          <div className="bg-white rounded-lg shadow-lg p-6">
            <h3 className="text-2xl font-bold text-gray-800 mb-4">Collision Stats</h3>
            <div className="space-y-6">
              {pieData ? (
                <Pie data={pieData} options={{ cutout: '60%' }} />
              ) : (
                <p className="text-gray-600">No data available for incident breakdown</p>
              )}
              {barData ? (
                <Bar data={barData} options={{ indexAxis: 'y' }} />
              ) : (
                <p className="text-gray-600">No data available for incident trends</p>
              )}
            </div>
          </div>
        </div>
      )}

      {isPanelOpen && (
        <AddEmployeePanel
          onClose={() => setIsPanelOpen(false)}
          onSave={handleAddEmployee}
          departments={departments}
          areas={["Warehouse 1", "Warehouse 2", "Site A", "Site B", "Head Office"]}
        />
      )}
    </div>
  );
};

export default UserProfile;
