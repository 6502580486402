import React from 'react';


const Settings = () => {
  return (
    <div className="flex">

    </div>
  );
};

export default Settings;