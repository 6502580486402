import React, { useState } from 'react';


const API_BASE_URL = 'https://api-e5tzoauvmq-uc.a.run.app';

const AddActionPanel = ({ onClose }) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [priority, setPriority] = useState('');
  const [deadlineDate, setDeadlineDate] = useState('');
  const [deadlineTime, setDeadlineTime] = useState('');
  const [media, setMedia] = useState(null);

  const handleMediaUpload = (e) => {
    if (e.target.files && e.target.files[0]) {
      console.log('Media selected:', e.target.files[0]); // Log the selected media
      setMedia(e.target.files[0]); // Correctly set the media
    } else {
      console.error('No media file selected.');
    }
  };
  

  const handleSave = async () => {
    try {
      let mediaUrl = null;
  
      // Step 1: Upload Media File
      if (media) { // Check if a media file is selected
        const formData = new FormData();
        formData.append('file', media);
        formData.append('bucketName', 'contractoai'); // Specify the bucket name
  
        console.log('Uploading media:', media);
  
        const uploadResponse = await fetch(`${API_BASE_URL}/api/storage/upload-action-media`, {
          method: 'POST',
          body: formData,
        });
  
        if (!uploadResponse.ok) {
          console.error('Media upload failed:', uploadResponse.status, await uploadResponse.text());
          throw new Error('Failed to upload media.');
        }
  
        const uploadData = await uploadResponse.json();
        console.log('Media Upload Response:', uploadData);
  
        mediaUrl = uploadData.publicURL; // Ensure public URL is returned
        if (!mediaUrl) {
          throw new Error('Failed to generate public URL for uploaded media.');
        }
      }
  
      // Step 2: Combine Deadline Date and Time
      const deadlineDatetime = `${deadlineDate}T${deadlineTime}:00`;
  
      // Step 3: Save Action to Database
      console.log('Saving action with the following data:', {
        title,
        description,
        priority,
        deadline_datetime: deadlineDatetime,
        media_url: mediaUrl,
      });
  
      const saveResponse = await fetch(`${API_BASE_URL}/api/database/add-action`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          title,
          description,
          priority,
          deadline_datetime: deadlineDatetime,
          media_url: mediaUrl,
        }),
      });
  
      if (!saveResponse.ok) {
        console.error('Save action failed:', saveResponse.status, await saveResponse.text());
        throw new Error('Failed to save action to the database.');
      }
  
      const saveData = await saveResponse.json();
      console.log('Action Save Response:', saveData);
  
      // Step 4: Alert Success and Close Panel
      alert('Action saved successfully!');
      onClose();
    } catch (error) {
      console.error('Error in handleSave:', error.message);
      alert(error.message);
    }
  };
  
  
  

  return (
    <div
      className="fixed right-0 top-[55px] h-[calc(100%-55px)] w-full sm:w-2/3 md:w-1/2 lg:w-1/3 bg-gray-50 shadow-lg rounded-xl p-8 flex flex-col space-y-6 overflow-y-auto"
    >
      {/* Header */}
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold text-darkblue">Add Action</h2>
        <button onClick={onClose} className="text-gray-400 hover:text-gray-600 text-xl">
          ✖
        </button>
      </div>

      {/* Media Upload */}
      <div className="border-dashed border-2 border-lightskyblue bg-white px-4 py-6 rounded-lg flex flex-col items-center">
        <div className="text-4xl sm:text-5xl text-skyblue">📎</div>
        <p className="text-gray-500 text-center mt-2">Upload your media (Ex. Img, Vid, Doc, Aud)</p>
        <input
          type="file"
          accept="image/*,video/*,audio/*,.pdf"
          className="mt-4"
          onChange={handleMediaUpload}
        />
      </div>

      {/* Title Input */}
      <div>
        <h3 className="text-sm font-semibold text-gray-700 mb-2">TITLE</h3>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Enter title"
          className="w-full p-2 border-b border-gray-300 bg-transparent focus:outline-none focus:border-skyblue"
        />
      </div>

      {/* Description Input */}
      <div>
        <h3 className="text-sm font-semibold text-gray-700 mb-2">DESCRIPTION</h3>
        <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Enter description"
          className="w-full pt-2 border-b border-gray-300 bg-transparent focus:outline-none focus:border-skyblue resize-none"
          rows="3"
        ></textarea>
      </div>

      {/* Priority Selection */}
      <div>
        <h3 className="text-sm font-semibold text-gray-700 mb-2">Choose Priority</h3>
        <div className="flex flex-wrap gap-2">
          <button
            onClick={() => setPriority('urgent')}
            className={`flex-1 px-4 py-2 rounded-md text-white ${
              priority === 'urgent' ? 'bg-red' : 'bg-red'
            }`}
          >
            Urgent
          </button>
          <button
            onClick={() => setPriority('high')}
            className={`flex-1 px-4 py-2 rounded-md text-white ${
              priority === 'high' ? 'bg-orange' : 'bg-orange'
            }`}
          >
            High
          </button>
          <button
            onClick={() => setPriority('medium')}
            className={`flex-1 px-4 py-2 rounded-md text-white ${
              priority === 'medium' ? 'bg-yellow' : 'bg-yellow'
            }`}
          >
            Medium
          </button>
          <button
            onClick={() => setPriority('low')}
            className={`flex-1 px-4 py-2 rounded-md text-white ${
              priority === 'low' ? 'bg-green' : 'bg-green'
            }`}
          >
            Low
          </button>
        </div>
      </div>

      {/* Deadline Input */}
      <div>
        <h3 className="text-sm font-semibold text-gray-700 mb-2">Deadline</h3>
        <div className="flex flex-col sm:flex-row sm:space-x-4">
          <input
            type="date"
            value={deadlineDate}
            onChange={(e) => setDeadlineDate(e.target.value)}
            className="w-full sm:w-auto p-2 border border-gray-300 rounded-md focus:outline-none focus:border-skyblue"
          />
          <input
            type="time"
            value={deadlineTime}
            onChange={(e) => setDeadlineTime(e.target.value)}
            className="w-full sm:w-auto p-2 border border-gray-300 rounded-md focus:outline-none focus:border-skyblue mt-2 sm:mt-0"
          />
        </div>
      </div>

      {/* Save Button */}
      <div className="mt-auto">
        <button
          onClick={handleSave}
          className="bg-skyblue text-white px-10 py-3 w-full rounded-xl shadow-md hover:bg-blue-500"
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default AddActionPanel;
