import React from "react";

const UserNameProfile = ({ user }) => {
  return (
    <div className="flex justify-center mt-10">
      <div className="w-full max-w-3xl bg-white rounded-lg shadow-md p-8">
        <div className="flex items-center space-x-6 border-b pb-6 mb-6">
          <img
            src={user?.image || "https://via.placeholder.com/150"}
            alt="User Avatar"
            className="w-24 h-24 rounded-full border"
          />
          <div>
            <h1 className="text-3xl font-bold text-gray-800">
              {user?.name || "User Name"}
            </h1>
            <p className="text-gray-500">ID: {user?.id || "N/A"}</p>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <h2 className="text-lg font-bold text-gray-800">Department:</h2>
            <p className="text-gray-600">{user?.department || "N/A"}</p>
          </div>
          <div>
            <h2 className="text-lg font-bold text-gray-800">Duties:</h2>
            <p className="text-gray-600">
              {Array.isArray(user?.duties)
                ? user.duties.join(", ")
                : "Duties information unavailable or not in array format."}
            </p>
          </div>
          <div>
            <h2 className="text-lg font-bold text-gray-800">Area:</h2>
            <p className="text-gray-600">{user?.area || "N/A"}</p>
          </div>
          <div>
            <h2 className="text-lg font-bold text-gray-800">Contact Details:</h2>
            <p className="text-gray-600">
              <span className="font-medium">Government ID:</span> {user?.government_id || "N/A"}
            </p>
            <p className="text-gray-600">
              <span className="font-medium">Phone:</span> {user?.phone || "N/A"}
            </p>
            <p className="text-gray-600">
              <span className="font-medium">Email:</span> {user?.email || "N/A"}
            </p>
            <p className="text-gray-600">
              <span className="font-medium">Address:</span> {user?.address || "N/A"}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserNameProfile;
