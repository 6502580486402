import React from "react";
import privacysecurity from "../Assets/privacysecurity.svg"; // Replace this with your actual SVG file
import privacysecurityimg from "../Assets/privacysecurityimg.jpeg"; // Replace with the actual image path

const PrivacySecuirty = () => {
  return (
    <section className="w-full bg-white py-20 flex justify-center">
      <div className="flex flex-col lg:flex-row items-start justify-between max-w-7xl mx-auto px-6 lg:px-16 space-y-8 lg:space-y-0 lg:space-x-40">
        
        {/* Left column with text content and buttons */}
        <div className="lg:w-[45%] min-w-[300px] space-y-6 text-left">
          <h1 className="text-5xl font-bold leading-tight text-darkblue">
          Unwavering Commitment to Privacy & Security
          </h1>
          <p className="text-lg max-w-md text-darkblue">
          We prioritize your data and trust, designing our processes and products to uphold the highest standards of security and privacy.          </p>
          {/* <div className="flex space-x-4">
            <button className="bg-buttonBlue text-white px-6 py-2 rounded font-medium shadow-md hover:bg-blue-600">
              Learn More
            </button>
          </div> */}
        </div>

        {/* Right column with image and overlay icon */}
        <div className="relative lg:w-[55%] flex justify-center lg:justify-end">
          <img 
            src={privacysecurityimg} 
            className="w-full max-w-2xl rounded-2xl shadow-lg object-cover" // Increased width, removed rounded corners
            alt="Privacy"
          />
          {/* <img
            src={privacysecurity}
            alt="Privacy Icon"
            className="absolute top-[90px] left-[-80px] lg:top-[90px] lg:left-[-80px] w-35 h-35"
          /> */}
        </div>
      </div>
    </section>
  );
};

export default PrivacySecuirty;
