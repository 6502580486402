import React, { useState, useEffect } from 'react';
import axios from 'axios';


const AddCollisionPanel = ({ onClose }) => {
  const [collisionType, setCollisionType] = useState('');
  const [area, setArea] = useState('');
  const [severity, setSeverity] = useState('');
  const [machines, setMachines] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [secondMachines, setSecondMachines] = useState([]);
  const [datetime, setDatetime] = useState('');
  const [availableMachines, setAvailableMachines] = useState([]);
  const [availableEmployees, setAvailableEmployees] = useState([]);
  const [collisionSeverities, setCollisionSeverities] = useState([]);

  useEffect(() => {
    fetchMachines();
    fetchEmployees();
    fetchCollisionSeverities();
  }, []);
  
  const fetchMachines = async () => {
    try {
      const response = await axios.get('/api/database/fetch-machines');
      if (response.data.success) {
        setAvailableMachines(response.data.data);
        console.log('Available Machines:', response.data.data);
      } else {
        console.error('Error fetching machines:', response.data.message);
      }
    } catch (err) {
      console.error('Error fetching machines:', err.message);
    }
  };
  
  const fetchEmployees = async () => {
    try {
      const response = await axios.get('/api/database/fetch-employee-data');
      if (response.data.success) {
        setAvailableEmployees(response.data.data);
        console.log('Available Employees:', response.data.data);
      } else {
        console.error('Error fetching employees:', response.data.message);
      }
    } catch (err) {
      console.error('Error fetching employees:', err.message);
    }
  };
  
  const fetchCollisionSeverities = async () => {
    try {
      const response = await axios.get('/api/database/collision-severities');
      if (response.data.success) {
        setCollisionSeverities(response.data.data);
        console.log('Available Severities:', response.data.data);
      } else {
        console.error('Error fetching severities:', response.data.message);
      }
    } catch (err) {
      console.error('Error fetching severities:', err.message);
    }
  };
  
  

  const handleMultiSelectChange = (value, setState, state) => {
    if (!state.includes(value)) {
      setState([...state, value]);
    }
  };

  const handleRemoveSelected = (value, setState, state) => {
    setState(state.filter((item) => item !== value));
  };

  const handleSave = async () => {
    try {
      if (!collisionType || !area || !severity || !datetime) {
        alert('All fields are required.');
        return;
      }

      const payload = {
        collision_type_id: collisionType === 'machineToHuman' ? 1 : 2,
        area,
        severity_id: severity,
        datetime: new Date(datetime).toISOString(),
        machines,
        employees: collisionType === 'machineToHuman' ? employees : undefined,
        secondMachines: collisionType === 'machineToMachine' ? secondMachines : undefined,
      };

      const response = await axios.post('/api/database/add-collision', payload);
      if (response.data.success) {
        alert('Collision data saved successfully.');
        onClose();
      } else {
        alert('Failed to save collision data: ' + response.data.message);
      }
    } catch (err) {
      console.error('Error saving collision data:', err.message);
      alert('Failed to save collision data. Please try again.');
    }
  };

  return (
    <div className="fixed right-0 top-[55px] h-[calc(100%-55px)] w-full sm:w-2/3 md:w-1/2 lg:w-1/3 bg-gray-50 shadow-lg rounded-xl p-8 flex flex-col space-y-2 overflow-y-auto">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold text-darkblue">Add Collision</h2>
        <button onClick={onClose} className="text-gray-400 hover:text-gray-600 text-xl">
          ✖
        </button>
      </div>

      <div className="flex-grow flex flex-col space-y-8">
        <div>
          <h3 className="text-xl font-semibold text-gray-700 my-6">Collision Type</h3>
          <select
            value={collisionType}
            onChange={(e) => setCollisionType(e.target.value)}
            className="w-full p-2 border-b border-gray-300 bg-transparent focus:outline-none focus:border-skyblue"
          >
            <option value="">Select Collision Type</option>
            <option value="machineToHuman">Machine to Human Collision</option>
            <option value="machineToMachine">Machine to Machine Collision</option>
          </select>
        </div>

        {collisionType && (
          <>
            <div>
              <h3 className="text-lg font-semibold text-gray-700">Collision Details</h3>
              <div className="flex flex-col sm:flex-row sm:space-x-6">
                <select
                  value={area}
                  onChange={(e) => setArea(e.target.value)}
                  className="flex-1 p-2 border-b border-gray-300 bg-transparent focus:outline-none focus:border-skyblue"
                >
                  <option value="">Select Area</option>
                  <option value="Area 1">Area 1</option>
                  <option value="Area 2">Area 2</option>
                  <option value="Area 3">Area 3</option>
                </select>
                <select
                    value={severity}
                    onChange={(e) => setSeverity(e.target.value)}
                    className="flex-1 p-2 border-b border-gray-300 bg-transparent focus:outline-none focus:border-skyblue"
                    >
                    <option value="">Select Severity</option>
                    {collisionSeverities.map((severity) => (
                        <option key={severity.id} value={severity.id}>
                        {severity.severity}
                        </option>
                    ))}
                </select>

              </div>
            </div>

            <div>
              <h3 className="text-lg font-semibold text-gray-700">Involved Entities</h3>
              <div className="flex flex-col sm:flex-row sm:space-x-6">
                <div className="flex-1">
                  <h4 className="text-sm font-semibold text-gray-700 mb-2">Select Machines</h4>
                  <select
                    onChange={(e) => handleMultiSelectChange(e.target.value, setMachines, machines)}
                    className="w-full p-2 border border-gray-300 bg-transparent focus:outline-none focus:border-skyblue"
                  >
                    <option value="">Select Machine</option>
                    {availableMachines.map((machine) => (
                      <option key={machine.id} value={machine.id}>
                        {machine.name}
                      </option>
                    ))}
                  </select>
                  <div className="mt-4 flex flex-wrap gap-4">
                    {machines.map((machine) => (
                      <div
                        key={machine}
                        className="bg-gray-200 text-sm text-gray-700 rounded-md px-4 py-2 flex items-center space-x-2"
                      >
                        <span>{machine}</span>
                        <button
                          onClick={() => handleRemoveSelected(machine, setMachines, machines)}
                          className="text-purple-500 hover:text-purple-700"
                        >
                          ✖
                        </button>
                      </div>
                    ))}
                  </div>
                </div>

                {collisionType === 'machineToHuman' && (
                  <div className="flex-1">
                    <h4 className="text-sm font-semibold text-gray-700 mb-2">Select Employees</h4>
                    <select
                      onChange={(e) => handleMultiSelectChange(e.target.value, setEmployees, employees)}
                      className="w-full p-2 border border-gray-300 bg-transparent focus:outline-none focus:border-skyblue"
                    >
                      <option value="">Select Employee</option>
                      {availableEmployees.map((employee) => (
                        <option key={employee.id} value={employee.id}>
                          {`${employee.first_name} ${employee.last_name}`}
                        </option>
                      ))}
                    </select>
                    <div className="mt-4 flex flex-wrap gap-4">
                      {employees.map((employee) => (
                        <div
                          key={employee}
                          className="bg-gray-200 text-sm text-gray-700 rounded-md px-4 py-2 flex items-center space-x-2"
                        >
                          <span>{employee}</span>
                          <button
                            onClick={() => handleRemoveSelected(employee, setEmployees, employees)}
                            className="text-purple-500 hover:text-purple-700"
                          >
                            ✖
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                {collisionType === 'machineToMachine' && (
                  <div className="flex-1">
                    <h4 className="text-sm font-semibold text-gray-700 mb-2">Select Second Machines</h4>
                    <select
                      onChange={(e) => handleMultiSelectChange(e.target.value, setSecondMachines, secondMachines)}
                      className="w-full p-2 border border-gray-300 bg-transparent focus:outline-none focus:border-skyblue"
                    >
                      <option value="">Select Second Machine</option>
                      {availableMachines.map((machine) => (
                        <option key={machine.id} value={machine.id}>
                          {machine.name}
                        </option>
                      ))}
                    </select>
                    <div className="mt-4 flex flex-wrap gap-4">
                      {secondMachines.map((machine) => (
                        <div
                          key={machine}
                          className="bg-gray-200 text-sm text-gray-700 rounded-md px-4 py-2 flex items-center space-x-2"
                        >
                          <span>{machine}</span>
                          <button
                            onClick={() => handleRemoveSelected(machine, setSecondMachines, secondMachines)}
                            className="text-purple-500 hover:text-purple-700"
                          >
                            ✖
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div>
              <h4 className="text-sm font-semibold text-gray-700 mb-2">Select Date and Time of Collision</h4>
              <input
                type="datetime-local"
                value={datetime}
                onChange={(e) => setDatetime(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:border-skyblue"
              />
            </div>
          </>
        )}
      </div>

      <div className="mt-auto">
        <button
          onClick={handleSave}
          className="bg-skyblue text-white px-10 py-3 w-full rounded-xl shadow-md hover:bg-blue-500"
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default AddCollisionPanel;
